import {Workbox} from 'workbox-window'

export function registerServiceWorker() {
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('sw.js')

    wb.addEventListener('installed', (event) => {
      /**
       * We have the condition - event.isUpdate because we don't want to show
       * this message on the very first service worker installation,
       * only on the updated
       */

      if (event.isUpdate) {
        if (confirm(`New app update is available!. Click OK to refresh`)) {
          window.location.reload()
        }
      }
    })
    wb.addEventListener('install', () => {
      // Skip over the "waiting" lifecycle state, to ensure that our
      // new service worker is activated immediately, even if there's
      // another tab open controlled by our older service worker code.
      wb.skipWaiting()
    })
    wb.addEventListener('activate', () => {
      // Optional: Get a list of all the current open windows/tabs under
      // our service worker's control, and force them to reload.
      // This can "unbreak" any open windows/tabs as soon as the new
      // service worker activates, rather than users having to manually reload.
      wb.clients
          .matchAll({
            type: 'window',
          })
          .then((windowClients) => {
            windowClients.forEach((windowClient) => {
              windowClient.navigate(windowClient.url)
            })
          })
    })

    wb.register()
  }
}
