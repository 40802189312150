import {Router} from 'react-router-dom'

import history from './browserHistory'
import {registerServiceWorker} from './serviceWorkerRegistration'
import {getAppConfig} from './config'

function getComponent() {
  return Promise.all([
    import('react'),
    import('./AppContainer'),
    import('react-dom'),
  ]).then((resp) => {
    const {default: React} = resp[0]
    const {default: AppContainer} = resp[1]
    const {default: ReactDOM} = resp[2]

    const contextProps =
      process.env.NODE_ENV === 'test'
        ? {
            authenticationContextProps: {user: null},
            cartContextProps: {},
            navigationContextProps: {isSideMenuOpen: true},
            themeContextProps: {theme: 'light'},
            featureNotificationContextProps: {
              featureFirstSeenFlags: getAppConfig().featureFirstSeenFlags,
            },
            NFTContextProps: {},
          }
        : {
            featureNotificationContextProps: {
              featureFirstSeenFlags: getAppConfig().featureFirstSeenFlags,
            },
          }

    const element = document.getElementById('app-container')

    if (window.parent.Cypress) {
      window.tgHistory = history
    }

    ReactDOM.render(
      <Router history={history}>
        <AppContainer {...contextProps} />
      </Router>,
      element,
    )
  })
}

getComponent()

registerServiceWorker()
